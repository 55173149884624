<template>
  <div class="website__domain-connect">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect title="Domain" route="website.domain" />
      </v-col>
      <v-col cols="12 page-header">
        <h2 class="mb-1 page-title">Buy new domain</h2>
        <router-link :to="{ name: 'website.domain.connect' }" class="text-decoration-none">
          + Connect Existing Domain
        </router-link>
      </v-col>
      <v-col cols="12" class="elevation-form px-5 py-5 mt-5">
        <div>
          <InputText label="Domain" placeholder="e.g. example.com" />
          <small>Enter the domain you want to connect</small>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InputText from '@/views/website/components/inputs/InputDefault';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';

export default {
  components: {
    InputText,
  },
  data() {
    return {
      domain: null,
    };
  },
};
</script>
